<style scoped>
.profile-user-info {
  border-top: unset;
  margin-top: unset;
}
.table-device{
  border: unset solid;
}
th, td {
  border: 1px solid #343a40;
}
.dotted-text {
  text-decoration: underline dashed #33cabb88;
  color: unset;
}
.dotted-text:hover {
  text-decoration: unset;
}
a.disabled {
  pointer-events: none;
}
button.disabled {
  pointer-events: none;
}
</style>
<template>
  <div class="box">
    <div class="box-body">
      <div class="flexbox pb-1">
        <h6 class="d-flex align-items-center">
          <LiveEdit
            class="text-primary"
            :value="currentItem.name"
            :show-buttons="false"
            :enabled="isOpen"
            @valueChanged="onNameChanged(id, ...arguments)"
          />
        </h6>
        <h6 class="d-flex align-items-center">
          <Icon icon="bx:bxs-lock-alt" :inline="true" class="text-default float-right mr-1" />
          <Toggle
            v-if="currentItem.tinchost!=='sol'"
            class="box-controls float-left btn btn-toggle btn-xs btn-primary"
            :value="!isOpen"
            @click="onSingleToggleStatus(id, currentItem.status)"
          />

          <div v-if="privileges.indexOf('NEMOS-SHARE')>-1" class="btn-group float-left ml-2">
            <button
              type="button" class="btn btn-sm btn-default dropdown-toggle" title="Compartir"
              data-toggle="dropdown" :class="{ 'text-warning': shareCount > 0 }"
            >
              <Icon v-if="shareCount > 0" icon="fa-solid:users" :inline="true" />
              <Icon v-else icon="fa-solid:user" :inline="true" />
            </button>
            <div class="dropdown dropdown-menu dropdown-sm">
              <h6 class="dropdown-header ">
                <span class="badge badge-pill badge-primary">Compartir con:</span>
              </h6>
              <a
                v-for="it in sharedUsers" :key="it.id" class="dropdown-item"
                :class="{ 'text-primary': it.sharing }" href="#" @click.prevent="toggleSharing(it)"
              >
                <svg
                  v-if="it.sharing" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                  aria-hidden="true" role="img" style="vertical-align: -0.125em;" width="1em" height="1em"
                  preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"
                >
                  <g fill="currentColor">
                    <path
                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417L5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                    />
                  </g>
                </svg>
                {{ it.email }}
              </a>
            </div>
          </div>
        </h6>
      </div>
      <div class="row mt-2 py-3 border-bottom border-top border-dark">
        <div class="col-2 text-right">
          HOSTNAME
        </div>
        <div class="col-3">
          <a
            href="#"
            class="text-primary"
            :class="{ disabled:!isOpen, 'text-danger': !currentItem.tinchost, 'dotted-text': isOpen, 'text-muted': !isOpen }"
            @click.prevent="onVpnHostameChange(id, currentItem.tinchost)"
          >
            {{ currentItem.tinchost ? currentItem.tinchost : "VPN hostname" }}
          </a>
        </div>
        <div class="col-3">
          <a
            href="#"
            class="text-primary"
            :class="{ disabled:!isOpen, 'text-danger': !currentItem.tincip, 'dotted-text': isOpen, 'text-muted': !isOpen }"
            @click.prevent="onIpChange(id, currentItem.tincip)"
          >
            {{ currentItem.tincip ? currentItem.tincip : "VPN IP" }}
          </a>
        </div>
        <div class="col-4">
          <Icon
            icon="ic:outline-cloud-download" width="1.2em" height="1.2em"
            style="vertical-align: -0.225em;" class="text-muted"
          />
          <a href="#" class="disabled">
            tinc file
          </a>
        </div>
      </div>
      <div
        v-if="tincEnabled && privileges && (privileges.indexOf('DEVICES-SSH') > -1)"
        class="row py-3 border-bottom border-dark"
      >
        <div class="col-2 text-right">
          SSH
        </div>
        <div class="col-10">
          <span class="text-muted d-block">
            {{ tincssh }}
          </span>
          <span v-if="devClass.value==='nemos'" class="text-muted d-block">
            {{ tincweb }}
          </span>
        </div>
      </div>
      <div class="row py-3 border-bottom border-dark">
        <div class="col-2 text-right">
          EDIS
        </div>
        <div class="col-3">
          <a
            href="#"
            class="text-primary"
            :class="{ disabled:!isOpen, 'text-danger': !currentItem.hwuid, 'dotted-text': isOpen, 'text-muted': !isOpen }"
            @click.prevent="onUidChange(id, currentItem.hwuid)"
          >
            <Icon icon="ic:baseline-key" width="1.25em" height="1.25em" />
            {{ currentItem.hwuid ? currentItem.hwuid : "LayerFX UID" }}
          </a>
        </div>
        <div class="col-3">
          <a
            v-if="privileges && (privileges.indexOf('DEVICES-LICENSE') > -1)"
            href="#"
            class=""
            :class="{ disabled:!currentItem.devserial_seq, 'text-danger': !currentItem.devserial_seq && isOpen }"
            @click.prevent="clickDownloadZpl(currentItem)"
          >
            <Icon icon="ic:outline-barcode" width="1.25em" height="1.25em" />
            {{ padSerial }}
          </a>
          <span v-else>
            <Icon icon="ic:outline-barcode" width="1.25em" height="1.25em" />
            {{ padSerial }}
          </span>
        </div>
        <div class="col-4">
          <Toggle
            class="box-controls btn btn-toggle btn-xs btn-default float-left"
            :value="currentItem.lfxlicensed"
            :enable="isOpen"
            @click="onToggleLicense(id, currentItem.lfxlicensed)"
          />
          &nbsp;
          <a
            v-if="currentItem.lfxlicensed && privileges && (privileges.indexOf('DEVICES-LICENSE') > -1)"
            href="#"
            @click.prevent="clickDownloadLicense(currentItem)"
          >
            <Icon icon="ic:outline-cloud-download" :inline="true" width="1.25em" height="1.25em" />
          </a>
          <Icon v-else icon="ic:outline-cloud-download" :inline="true" width="1.25em" height="1.25em" />
        </div>
      </div>
      <div class="row py-3 border-bottom border-dark">
        <div class="col-2 text-right">
          LAT LON
        </div>
        <div class="col-3">
          <a
            href="#"
            class="text-primary"
            :class="{ disabled:!isOpen, 'text-danger': Number.isNaN(parseFloat(currentItem.lat)), 'dotted-text': isOpen, 'text-muted': !isOpen }"
            @click.prevent="onGpsChange(id, 'lat', currentItem.lat)"
          >
            {{ !Number.isNaN(parseFloat(currentItem.lat)) ? currentItem.lat : "lat" }}
          </a>
        </div>
        <div class="col-3">
          <a
            href="#"
            class="text-primary"
            :class="{ disabled:!isOpen, 'text-danger': Number.isNaN(parseFloat(currentItem.lon)), 'dotted-text': isOpen, 'text-muted': !isOpen }"
            @click.prevent="onGpsChange(id, 'lon', currentItem.lon)"
          >
            {{ !Number.isNaN(parseFloat(currentItem.lon)) ? currentItem.lon : "lon" }}
          </a>
        </div>
        <div class="col-4">
          <LiveEdit
            class="text-primary"
            :value="currentItem.place_name"
            :empty="`Sin lugar asignado`"
            :show-buttons="false"
            :enabled="isOpen"
            @valueChanged="onPlaceNameChanged(id, ...arguments)"
          />
        </div>
      </div>
      <div class="row py-3 border-bottom border-dark">
        <div class="col-6">
          <div class="row">
            <div class="col-4 text-right">
              System
            </div>
            <div class="col-8">
              <p class="text-muted mb-0">
                <!-- <Icon icon="ic:baseline-power-settings-new" />
            {{ formatDateTime(currentItem.last_boot) }}
            <br> -->
                <Icon icon="iconoir:activity" />
                {{ formatDateTime(currentItem.last_activity) }}
                <br>
              </p>
            </div>
          </div>
          <div class="row d-flex align-items-center mt-3">
            <div class="col-4 text-right">
              Model
            </div>
            <div class="col-8">
              <p v-if="!isOpen" class="text-muted mb-0">
                {{ devClass.name }}
                <br>
              </p>
              <div v-if="isOpen" class="btn-group float-left">
                <button
                  type="button" class="btn btn-sm btn-default dropdown-toggle" title="Compartir"
                  data-toggle="dropdown"
                >
                  {{ devClass.name }}
                </button>
                <div class="dropdown dropdown-menu dropdown-sm">
                  <a class="dropdown-item" href="#" @click.prevent="onDevclassChange('generic')">Genérico</a>
                  <a class="dropdown-item" href="#" @click.prevent="onDevclassChange('player')">Player</a>
                  <a class="dropdown-item" href="#" @click.prevent="onDevclassChange('nemos')">Nemos</a>
                  <a class="dropdown-item" href="#" @click.prevent="onDevclassChange('weather')">Weather</a>
                </div>
              </div>
            </div>
          </div>
          <div v-if="devClass.value==='weather' && socialnetsPlots.indexOf(tincIp)>-1" class="row d-flex align-items-center mt-3">
            <div class="col-4 text-right">
              &nbsp;
            </div>
            <div class="col-8">
              <p class="text-muted mb-0">
                <a
                  :href="`${API_ROOT}/api/devices/plots/${tincIp}/socialnets_${new Date().getFullYear()}.png`"
                  @click.prevent="openSocialnetsPlot"
                >
                  <Icon icon="icomoon-free:stats-bars2" :inline="true" /> Gráfica redes sociales </a>
                <br>
              </p>
            </div>
          </div>
        </div>
        <div class="col-6">
          <img
            width="192"
            height="108"
            :src="screenshot"
            @click.prevent="confirmScreenshot"
            @error="screenshot = '/img.png'"
          >
        </div>
      </div>
      <div v-if="devClass.value==='nemos'" class="row py-3 px-3">
        <ul class="nav nav-tabs w-100" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" href="#plots" role="tab" aria-expanded="false" @click="tabClicked('plots')">
              <Icon icon="material-symbols:area-chart" :inline="true" /> &nbsp;Monitoreo
            </a>
          </li>
          <li v-if="privileges.indexOf('DEVICES-NEMOS-DOWNLOAD') > -1" class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#downloads" role="tab" aria-expanded="true" @click="tabClicked('downloads')">
              <Icon icon="ic:outline-cloud-download" :inline="true" /> &nbsp;Descargar
            </a>
          </li>
          <li v-if="privileges.indexOf('DEVICES-NEMOS-ENQUEUE') > -1" class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#enqueue" role="tab" aria-expanded="false" @click="tabClicked('enqueue')">
              <Icon icon="carbon:message-queue" :inline="true" /> &nbsp;Encolar
            </a>
          </li>
        </ul>
        <div class="tab-content tabcontent-border w-100">
          <div id="plots" class="tab-pane pad active" role="tabpanel" aria-expanded="true">
            <div class="row">
              <div class="col-3">
                <input id="r_hour" v-model="plotRange" value="hour" type="radio" class="with-gap" @change="onPlotRangeChange($event)">
                <label for="r_hour">Hora</label>
              </div>
              <div class="col-3">
                <input id="r_day" v-model="plotRange" value="day" type="radio" class="with-gap" @change="onPlotRangeChange($event)">
                <label for="r_day">Día</label>
              </div>
              <div class="col-3">
                <input id="r_week" v-model="plotRange" value="week" type="radio" class="with-gap" @change="onPlotRangeChange($event)">
                <label for="r_week">Semana</label>
              </div>
              <div class="col-3">
                <input id="r_month" v-model="plotRange" value="month" type="radio" class="with-gap" @change="onPlotRangeChange($event)">
                <label for="r_month">Mes</label>
              </div>
              <div class="col-12">
                <img
                  v-for="(img, idx) in currentPlotsUrls"
                  :key="idx"
                  :src="img"
                  class="col-md-6 col-sm-12"
                  loading="lazy"
                  @error="plotFillBrokenImage"
                >
              </div>
              <!-- </div> -->
            </div>
          </div>
          <div id="downloads" class="tab-pane pad" role="tabpanel" aria-expanded="false">
            <DownloadsTable
              :item="item"
            />
          </div>
          <div id="enqueue" class="tab-pane pad" role="tabpanel" aria-expanded="false" @paste="onPaste">
            <div v-if="currentTab=='enqueue'" class="row py-1">
              <div class="col">
                <div v-show="queueSize < 1" class="row pad">
                  <div class="col-sm-12 col-md-6 col-lg-6">
                    <input id="r_tuner1" v-model="queueTuner" value="1" name="group1" type="radio" class="with-gap">
                    <label for="r_tuner1">{{ nemrecs[0] }}</label>
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-6">
                    <input id="r_tuner2" v-model="queueTuner" value="2" name="group1" type="radio" class="with-gap">
                    <label for="r_tuner2">{{ nemrecs[1] }}</label>
                  </div>
                </div>
                <div v-show="queueSize < 1" class="row px-2">
                  <div class="col-sm-12 col-md-6 col-lg-4">
                    <input
                      class="form-control text-info"
                      type="text"
                      value=""
                      placeholder="Pega aquí"
                      @keypress.prevent
                    >
                  </div>
                </div>
                <div class="row">
                  <EnqueueTable
                    class="col-12"
                    :raw-text="queuePasteText"
                    :tuner="queueTuner"
                    :item="item"
                    :nemrecs="nemrecs"
                    @clearPaste="queuePasteText=''"
                    @queueSizeChanged="onQueueSizeChanged"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row py-3">
        <CommentsPane
          class="col-md-12 col-lg-12"
          objecttype="devices"
          :parentid="item._id"
          :refresh="refresh"
          :enablecomment="privileges && (privileges.indexOf('DEVICES-COMMENT-CREATE') > -1)"
          @error="commentError"
        />
      </div>
    </div>
  </div>
</template>

<script>

import { Icon } from "@iconify/vue2";
import { format, parseISO, differenceInSeconds } from "date-fns";
import debounce from "lodash/debounce"; // no se puede lazy load
import esmx from "date-fns/locale/es";
import { mapState } from "vuex";
import store from "@/store";
import LiveEdit from "@/components/LiveEdit.vue";
import Toggle from "@/components/Toggle.vue";
import Comments from "@/components/Comments.service";
import UsersApi from "@/services/Users.service";
import ServiceApi from "./Devices.service";

const plot404 = require("./plotnotfound.png");
// Lazy loaders
const CommentsPane = () => import("@/components/CommentsPane.vue");
const DownloadsTable = () => import("./DownloadsTable.vue");
const EnqueueTable = () => import("./EnqueueTable.vue");

export default {
  name: "DevicesSingle",
  components: {
    LiveEdit,
    CommentsPane,
    Toggle,
    Icon,
    DownloadsTable,
    EnqueueTable
  },
  props: {
    item: {
      type: Object,
      default() {
        return {
          _id: null,
          hwuid: "",
          name: "",
          tinchost:"",
          tincip: "",
          status: "open",
          lfxlicensed: false,
          devserial_seq: 0,
          plotRange: "hour"
        };
      }
    }
  },
  data() {
    return {
      searchQuotes: [], // array de {id, label, item}
      refresh: 0,
      screenshot:"/img.png",
      sortDefault: [
        {
          field: "createdAt",
          sortField: "createdAt",
          direction: "desc"
        }
      ],
      currentItem: {
        _id: null,
        hwuid: "",
        name: "",
        tinchost:"",
        tincip: "",
        status: "open",
        lfxlicensed: false,
        devserial_seq: 0
      },
      plotRange: "hour",
      plots: {
        hour: [
          "tuner1_01h.png", "tuner2_01h.png",
          "tuner1_signal_01h.png", "tuner2_signal_01h.png",
          "nemrecord_audiopeak1_01h.png", "nemrecord_audiopeak2_01h.png",
          "nemrecord1_01h.png", "nemrecord2_01h.png",
          "cputemp_01h.png", "cpuload_01h.png"
        ],
        day: [
          "tuner1_24h.png", "tuner2_24h.png",
          "tuner1_signal_24h.png", "tuner2_signal_24h.png",
          "nemrecord_audiopeak1_24h.png", "nemrecord_audiopeak2_24h.png",
          "nemrecord1_24h.png", "nemrecord2_24h.png",
          "cputemp_24h.png", "cpuload_24h.png"
        ],
        week: [
          "tuner1_07d.png", "tuner2_07d.png",
          "tuner1_signal_07d.png", "tuner2_signal_07d.png",
          "nemrecord_audiopeak1_07d.png", "nemrecord_audiopeak2_07d.png",
          "nemrecord1_07d.png", "nemrecord2_07d.png",
          "cputemp_07d.png", "cpuload_07d.png"
        ],
        month: [
          "tuner1_30d.png", "tuner2_30d.png",
          "tuner1_signal_30d.png", "tuner2_signal_30d.png",
          "nemrecord_audiopeak1_30d.png", "nemrecord_audiopeak2_30d.png",
          "nemrecord1_30d.png", "nemrecord2_30d.png",
          "cputemp_30d.png", "cpuload_30d.png"
        ],
        year: [
          "tuner1_01y.png", "tuner2_01y.png",
          "tuner1_signal_01y.png", "tuner2_signal_01y.png",
          "nemrecord_audiopeak1_01y.png", "nemrecord_audiopeak2_01y.png",
          "nemrecord1_01y.png", "nemrecord2_01y.png",
          "cputemp_01y.png", "cpuload_01y.png"
        ],
      },
      currentTab: "plots",
      queuePasteText: "",
      queueSize: 0,
      queueTuner: "1",
      nemrecs: ["Tuner 1", "Tuner 2"],
      availableUsersToShare: [],
      socialnetsPlots: [
        "10.11.0.158",
        "10.11.0.157",
        "10.11.0.152",
        "10.11.0.142"
      ]
    };
  },
  computed: {
    ...mapState(["API_ROOT", "privileges", "user"]),
    id() {
      const { _id = null } = this.currentItem ?? {};
      return _id;
    },
    isOpen() {
      const { status = "open", tinchost = "" } = this.currentItem ?? {};
      if (tinchost === "sol") {
        return false;
      }
      return status === "open";
    },
    relatedQuotationTitle() {
      const { quotation = {} } = this.currentItem;
      const { title = "", quoteid = "" } = quotation ?? {};
      if (quoteid.length > 0) {
        return `${quoteid}: ${title}`;
      }
      return "";
    },
    padSerial() {
      const { devserial_seq = 0 } = this.currentItem ?? {};
      return devserial_seq.toString().padStart(5, "0");
    },
    currentPlotsUrls() {
      const curr = this.plots[this.plotRange];
      const { tincip = null } = this.currentItem;

      if (!tincip || !Array.isArray(curr)) {
        return [];
      }
      // console.log(curr);
      const urls = curr.map(it => {
        // Armar toda la URL aqui
        const full = `${this.API_ROOT}/api/devices/plots/${tincip}/${it}`;
        return full;
      });
      // console.log(urls);
      return urls;
    },
    showPlots() {
      const { tinchost = "", last_activity: lastact = null } = this.currentItem;
      if (!lastact) {
        return false;
      }
      const dateOrig = parseISO(lastact);
      const diffsecs = Math.abs(differenceInSeconds(dateOrig, new Date()));
      return tinchost.startsWith("nemos") && diffsecs < 310;
    },
    tincEnabled() {
      const { tinchost = "", tincip = "" } = this.currentItem;
      return tincip.startsWith("10.") && tinchost && tinchost !== "sol";
    },
    devClass() {
      const { devclass = "generic" } = this.currentItem;
      const devclassNames = {
        generic: "Genérico",
        player: "Player",
        nemos: "Nemos",
        weather: "Weather"
      };
      return { value: devclass, name: devclassNames[devclass] ?? "Genérico" };
    },
    tincIp() {
      const { tincip = "" } = this.currentItem ?? {};
      return tincip;
    },
    tincssh() {
      const { tincip = "" } = this.currentItem ?? {};
      const [,,, address = ""] = tincip.split(".");
      const port = address.slice(-2).padStart(2, "0");
      return `ssh -L 22${port}:${tincip}:22 admin@sol.edis.mx`;
    },
    tincweb() {
      const { tincip = "" } = this.currentItem ?? {};
      const [,,, address = ""] = tincip.split(".");
      const port = address.slice(-2).padStart(2, "0");
      return `ssh -L 80${port}:${tincip}:80 admin@sol.edis.mx`;
    },
    sharedUsers() {
      // Usuarios con acceso permitido en este momento
      const { users: currentUsers = [] } = this.currentItem;
      // console.log("Users", currentUsers, this.availableUsersToShare);
      const currentUserIds = currentUsers.map(it => it._id);
      const sharedUsers = this.availableUsersToShare.map(it => {
        const { id, email, name } = it;
        const sharing = currentUserIds.indexOf(id) > -1;
        return {
          id,
          email,
          name,
          sharing
        };
      });
      return sharedUsers;
    },
    shareCount() {
      const sharing = this.sharedUsers.filter(it => it.sharing);
      return sharing.length;
    }
  },

  watch: {
    item: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(val, old) {
        // console.log("Changed ITEM", old, val);
        this.screenshot = "/img.png";
        if (!val) {
          this.currentItem = {};
          return;
        }
        this.currentItem = val;
        const { tinchost =  "" } = this.currentItem ?? {};
        if (tinchost.includes("nemos")) {
          this.fillChannels();
        }
      }
    }
  },
  methods: {
    onPaste(ev) {
      ev.stopPropagation();
      ev.preventDefault();

      const { items } = ev.clipboardData || ev.originalEvent.clipboardData;
      // eslint-disable-next-line guard-for-in
      for (const index in items) {
        const item = items[index];
        // console.log("kind", item.kind);
        if (item.kind === "string") {
          const text = ev.clipboardData.getData("Text");
          // console.log("TEXT DETECTED", text);
          this.queuePasteText = text;
        }
      }
    },
    fillChannels() {
      ServiceApi.getChannels(this.tincIp, {})
        .then(res => {
        // console.log("Got channels", res);
          const [tuner1 = "Tuner 1", tuner2 = "Tuner 2"] = res ?? [];
          this.nemrecs = [tuner1, tuner2];
        })
        .catch(err => {
          console.log("Error get channels", err);
          this.nemrecs = ["Tuner 1", "Tuner 2"];
        });
    },
    plotFillBrokenImage(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.src = plot404;
    },
    onPlotRangeChange(event) {
      // const optionText = event.target.value;
      // console.log(optionText, event.target);
      this.plotRange = event.target.value;
    },
    confirmScreenshot() {
      console.log("Tomar screnshot", this.currentItem);
      const { tincip = "" } = this.currentItem ?? {};
      if (!this.isValidIP(tincip)) {
        this.screenshot = "/img.png";
        this.messageAlert({
          icon: "error",
          title: "<b>VPN IP</b> inválida",
          text: ""
        });
        return;
      }
      // /api/devices/screens/10.11.0.178
      // this.screenshot = `https://rkt.edis.mx/api/devices/screens/${tincip}?t=${(new Date()).getTime()}`;
      this.screenshot = `${store.state.API_ROOT}/api/devices/screens/${tincip}?t=${(new Date()).getTime()}`;
      console.log("Got", this.screenshot);
    },
    getLfxLicense() {
      console.log("Getting license", this.item, this.$route.params.id);
      const { hwserial = "thumbs" } = this.item;
      this.$emit("licenseClicked", this.$route.params.id, hwserial);
    },
    fillInitial() {
      console.log("Refilling from server", this.$route.params.id);
      const { id:cid = null } = this.$route.params;
      if (!cid) {
        console.log("Refusing to refill empty route");
        return;
      }
      ServiceApi.getById(this.$route.params.id).then(res => {
        if (!res) {
          // console.log("Got empty");
          this.currentItem = {};
          return;
        }
        // console.log("Got", res);
        this.currentItem = res;
      });
    },
    // eslint-disable-next-line func-names
    onNameChanged: debounce(function (id, name) {
      const { id: paramid = "" } = this.$route.params;
      if (!paramid || paramid !== id) {
        this.messageAlert({
          icon: "error",
          title: "No se pudo editar propiedad <b>nombre</b>",
          text: "Los identificadores no coinciden"
        });
        this.$nextTick(() => {
          this.currentItem = this.item ?? {};
        });
        return;
      }
      ServiceApi.updateById(id, { name })
        .then(res => {
          const { name: nameres } = res ?? {};
          const { name: oldname = "" } = this.item ?? {};
          this.fillInitial();
          const comment = `Se ha cambiado el nombre del dispositivo ${oldname} a ${nameres}`;
          if (this.privileges.indexOf("DEVICES-COMMENT-CREATE") === -1) {
            return res;
          }
          return Comments.pushCommentToId("devices", id, comment);
        })
        .then(() => {
          this.refresh += 1;
          this.$emit("requestRefresh", this.currentItem);
        })
        .catch(err => {
          const { response = {}, message = "" } = err;
          const { data: dataResponse = {}, status = 400 } = response;
          const { error_string: errstr = message } = dataResponse;
          console.log(status, errstr);
          this.$nextTick(() => {
            this.currentItem = this.item ?? {};
          });
          this.messageAlert({
            icon: "error",
            title: "No se pudo editar propiedad <b>nombre</b>",
            text: `${errstr}, CODE: ${status}`
          });
        });
    }, 500),
    // eslint-disable-next-line func-names
    onPlaceNameChanged: debounce(function (id, name) {
      const { id: paramid = "" } = this.$route.params;
      if (!paramid || paramid !== id) {
        this.messageAlert({
          icon: "error",
          title: "No se pudo editar propiedad <b>nombre del lugar</b>",
          text: "Los identificadores no coinciden"
        });
        this.$nextTick(() => {
          this.currentItem = this.item ?? {};
        });
        return;
      }
      ServiceApi.updateById(id, { place_name:name })
        .then(() => {
          this.fillInitial();
          this.$emit("requestRefresh", this.currentItem);
        })
        .catch(err => {
          const { response = {}, message = "" } = err;
          const { data: dataResponse = {}, status = 400 } = response;
          const { error_string: errstr = message } = dataResponse;
          console.log(status, errstr);
          this.$nextTick(() => {
            this.currentItem = this.item ?? {};
          });
          this.messageAlert({
            icon: "error",
            title: "No se pudo editar propiedad <b>nombre del lugar</b>",
            text: `${errstr}, CODE: ${status}`
          });
        });
    }, 500),
    onSingleToggleStatus(id, status) {
      console.log("TOGGLE STATUS", status);
      const { id: paramid = "" } = this.$route.params;
      if (!paramid || paramid !== id) {
        this.messageAlert({
          icon: "error",
          title: "No se pudo editar propiedad <b>nombre</b>",
          text: "El identificador no coincide con el elemento seleccionado"
        });
        return;
      }

      const statuschanged = status === "open" ? "locked" : "open";
      this.currentItem.status = statuschanged;
      ServiceApi.updateById(id, { status: statuschanged })
        .then(() => {
          this.fillInitial();
          this.$emit("requestRefresh", this.currentItem);
        })
        .catch(err => {
          const { response = {}, message = "" } = err;
          const { data: dataResponse = {}, status: statusres = 400 } = response;
          const { error_string: errstr = message } = dataResponse;
          console.log(statusres, errstr);
          this.messageAlert({
            icon: "error",
            title: "No se pudo editar propiedad <b>estado</b>",
            text: `${errstr}, CODE: ${statusres}`
          });
        });
    },
    onVpnHostameChange(id, hostname) {
      console.log("VPN Hostame value:", hostname);
      this.$swal({
        title: "Cambiar VPN Hostame",
        html: "Escribe el hostname",
        inputValue: hostname,
        inputPlaceholder: "",
        input: "text",
        inputAutoTrim: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancelar",
        showConfirmButton: true,
        showCancelButton: true,
        // eslint-disable-next-line consistent-return
        inputValidator: (val) => {
          if (val.length < 1) {
            return "VPN Hostame vacío";
          }
        }
      }).then((result) => {
        const { isConfirmed = false, value = "" } = result;
        if (isConfirmed) {
          const hname = value.trim();
          ServiceApi.updateById(id, { tinchost: hname })
            .then(() => {
              this.fillInitial();
              this.$emit("requestRefresh", this.currentItem);
            })
            .catch(err => {
              const { response = {}, message = "" } = err;
              const { data: dataResponse = {}, status = 400 } = response;
              const { error_string: errstr = message } = dataResponse;
              console.log(status, errstr);
              this.messageAlert({
                icon: "error",
                title: "No se pudo editar <b>VPN Hostame</b>",
                text: `${errstr}, CODE: ${status}`
              });
            });
        }
      });
    },
    onIpChange(id, ip) {
      console.log("VPN IP current value:", ip, "valid:", this.isValidIP(ip));
      this.$swal({
        title: "Cambiar VPN IP",
        html: "Escribe la IP",
        inputValue: ip,
        inputPlaceholder: "10.11.0.1",
        input: "text",
        confirmButtonText: "OK",
        cancelButtonText: "Cancelar",
        showConfirmButton: true,
        showCancelButton: true,
        reverseButtons: true,
        // eslint-disable-next-line consistent-return
        inputValidator: (val) => {
          if (!val) {
            return "IP vacío";
          }
          if (!this.isValidIP(val)) {
            return "IP inválida";
          }
        }
      }).then((result) => {
        // console.log(result);
        const { isConfirmed = false, value = "" } = result;
        if (isConfirmed && value.length > 0) {
          ServiceApi.updateById(id, { tincip: value })
            .then(() => {
              this.fillInitial();
              this.$emit("requestRefresh", this.currentItem);
            })
            .catch(err => {
              const { response = {}, message = "" } = err;
              const { data: dataResponse = {}, status = 400 } = response;
              const { error_string: errstr = message } = dataResponse;
              console.log(status, errstr);
              this.messageAlert({
                icon: "error",
                title: "No se pudo editar <b>VPN IP</b>",
                text: `${errstr}, CODE: ${status}`
              });
            });
        }
      });
    },
    onUidChange(id, uid) {
      console.log("HW UID current value:", uid);
      this.$swal({
        title: "Cambiar LayerFX UID",
        html: "<b class=\"text-danger\">¡Cuidado!</b><br>Ésto cambia un número de serie.<br><br>Confirma con <b>Cambiar</b>",
        inputValue: uid,
        inputPlaceholder: "",
        input: "text",
        inputAutoTrim: true,
        confirmButtonText: "Cambiar",
        cancelButtonText: "Cancelar",
        showConfirmButton: true,
        showCancelButton: true,
        allowEnterKey: false,
        focusConfirm: false,
        // returnFocus: false,
        // eslint-disable-next-line consistent-return
        inputValidator: (val) => {
          if (val.length < 1) {
            return "LayerFX UID vacío";
          }
          const alphanum = /^[a-zA-Z0-9:]+$/i;
          if (!alphanum.test(val)) {
            return "Solo acepta dígitos alfanuméricos";
          }
        }
      }).then((result) => {
        const { isConfirmed = false, value = "" } = result;
        if (isConfirmed) {
          const lfxuid = value.trim();
          ServiceApi.updateById(id, { hwuid: lfxuid })
            .then(() => {
              this.fillInitial();
              this.$emit("requestRefresh", this.currentItem);
            })
            .catch(err => {
              const { response = {}, message = "" } = err;
              const { data: dataResponse = {}, status = 400 } = response;
              const { error_string: errstr = message } = dataResponse;
              console.log(status, errstr);
              this.messageAlert({
                icon: "error",
                title: "No se pudo editar <b>LayerFX UID</b>",
                text: `${errstr}, CODE: ${status}`
              });
            });
        }
      });
    },
    onGpsChange(id, coord, latlon) {
      console.log(`GPS ${coord}: ${latlon}`);
      this.$swal({
        title: `Cambiar GPS ${coord === "lat" ? "Latitud" : "Longitud"}`,
        html: `Escribe la ${coord === "lat" ? "latitud" : "longitud"}`,
        inputValue: latlon ?? "",
        inputPlaceholder: coord === "lat" ? "19.05" : "-98.22",
        input: "text",
        confirmButtonText: "OK",
        cancelButtonText: "Cancelar",
        showConfirmButton: true,
        showCancelButton: true,
        reverseButtons: true,
        // eslint-disable-next-line consistent-return
        inputValidator: (val) => {
          if (Number.isNaN(parseFloat(val))) {
            return `Escriba una ${coord === "lat" ? "latitud" : "longitud"} correcta.`;
          }
        }
      }).then((result) => {
        // console.log(result);
        const { isConfirmed = false, value = "0" } = result;
        if (isConfirmed) {
          const query = {};
          query[coord] = parseFloat(value);
          // console.log(query);
          ServiceApi.updateById(id, query)
            .then(() => {
              this.fillInitial();
              this.$emit("requestRefresh", this.currentItem);
            })
            .catch(err => {
              const { response = {}, message = "" } = err;
              const { data: dataResponse = {}, status = 400 } = response;
              const { error_string: errstr = message } = dataResponse;
              console.log(status, errstr);
              this.messageAlert({
                icon: "error",
                title: `No se pudo editar <b>GPS ${coord === "lat" ? "Latitud" : "Longitud"}</b>`,
                text: `${errstr}, CODE: ${status}`
              });
            });
        }
      });
    },
    clickDownloadZpl(item) {
      // console.log(item);
      const {
        devserial_seq = 0,
        hwuid = "",
        product = "EDIS Player"
      } = item ?? {};

      if (!hwuid) {
        this.messageAlert({
          icon: "error",
          title: "No se pudo crear <b>Etiqueta ZPL</b>",
          text: "El hardware UID es inválido"
        });
        return;
      }

      const tplzpl = `^XA

^FX section with barcode.
^BY2,3,100
^FO40,20^BC^FD{number}^FS

^BY2,4,100
^FO380,20^BC^FD{number}^FS

^CF0,38
^FO50,200^FDEDIS Interactive^FS
^CF0,38
^FO390,200^FDEDIS Interactive^FS

^CF0,18
^FO50,155^FD{comment}^FS
^CF0,18
^FO50,175^FDProduct: {product}^FS

^CF0,18
^FO390,155^FD{comment}^FS
^CF0,18
^FO390,175^FDProduct: {product}^FS

^XZ

`;

      const serial = String(devserial_seq).padStart(9, "0");
      const uid = hwuid.split(":").join("").slice(-8).toUpperCase();
      let zpl = tplzpl;
      zpl = zpl.replaceAll("{number}", serial);
      zpl = zpl.replaceAll("{comment}", `${uid.substring(0, 4)}-${uid.substring(4, 8)}`);
      zpl = zpl.replaceAll("{product}", product);

      const element = document.createElement("a");
      const blob = new Blob([zpl], { type: "text/plain;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      element.setAttribute("href", url);
      element.setAttribute("download", `${serial}-zpl.prn`);
      element.click();
    },
    onToggleLicense(id, license) {
      console.log("TOGGLE LICENSE:", license);
      const { id: paramid = "" } = this.$route.params;
      if (!paramid || paramid !== id) {
        this.messageAlert({
          icon: "error",
          title: "No se pudo editar propiedad <b>auto-licencia</b>",
          text: "El identificador no coincide con el elemento seleccionado"
        });
        return;
      }

      const lfxlicensed = !license;
      this.currentItem.lfxlicensed = lfxlicensed;
      ServiceApi.updateById(id, { lfxlicensed })
        .then(() => {
          this.fillInitial();
          this.$emit("requestRefresh", this.currentItem);
        })
        .catch(err => {
          const { response = {}, message = "" } = err;
          const { data: dataResponse = {}, status: statusres = 400 } = response;
          const { error_string: errstr = message } = dataResponse;
          console.log(statusres, errstr);
          this.messageAlert({
            icon: "error",
            title: "No se pudo editar propiedad <b>auto-licencia</b>",
            text: `${errstr}, CODE: ${statusres}`
          });
        });
    },
    clickDownloadLicense(item) {
      const { _id: id = "" } = item ?? {};
      ServiceApi.getLicenseById(id)
        .then(res => {
          // console.log(res);
          const { license = "", err = "" } = res;
          if (err) {
            throw new Error(err);
          }
          const element = document.createElement("a");
          const blob = new Blob([license], { type: "binary/octet-stream;charset=utf-8;" });
          const url = URL.createObjectURL(blob);
          element.setAttribute("href", url);
          element.setAttribute("download", "thumbs");
          element.click();
        })
        .catch(err => {
          const { response = {}, message = "" } = err;
          const { data: dataResponse = {}, status: statusres = 400 } = response;
          const { error_string: errstr = message } = dataResponse;
          console.log(statusres, errstr);
          this.messageAlert({
            icon: "error",
            title: "No se pudo descargar <b>licencia</b>",
            text: `${errstr}, CODE: ${statusres}`
          });
        });
    },
    onQuotationSearch(val) {
      console.log("Searching...", val);
      if (!this.searching) {
        this.searching = true;
        ServiceApi.findRelatedQuotation(this.item._id, val )
          .then(res => {
            this.searching = false;
            const { rows = [] } = res;
            const results = rows.map(it => ({
              id: it._id,
              label: `${it.quoteid}: ${it.title}`,
              item: it
            }));
            this.searchQuotes = results;
          })
          .catch(err => {
            this.searching = false;
            console.log("Error in search", err);
          });
      }
    },
    async onTincFileEdit(id, tincfile, old) {
      const resp = await this.$swal({
        title: "¡Cuidado!",
        html: "Puedes dejar al aparato fuera de la red <br> <strong>¿Estás seguro?</strong>.<br> Anota abajo: <i>estoy seguro</i>",
        icon: "warning",
        confirmButtonText: "Ok",
        cancelButtonText: "Cancelar",
        showCancelButton: true,
        reverseButtons: true,
        type: "input",
        input: "text"
      });
      const { value = "" } = resp;
      if (!resp.isConfirmed || value !== "estoy seguro") {
        this.$swal({
          title: "Cancelado",
          position: "bottom-end",
          toast: true,
          timer: 2000,
          showConfirmButton: false,
          showCancelButton: false,
          timerProgressBar: true
        });
      }

      const pubkey = /-----BEGIN(.*)PUBLIC KEY-----(.*)-----END(.*)PUBLIC KEY-----/s;
      const subnet = /^Subnet = (\b25[0-5]|\b2[0-4][0-9]|\b[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\/\d{1,}/s;

      if (     !tincfile.startsWith("Subnet")
            || !subnet.test(tincfile)
            || !pubkey.test(tincfile)) {
        this.$swal({
          title: "El archivo no es correcto checar con cuidado, se ignoró el cambio.",
          position: "bottom-end",
          toast: true,
          timer: 2000,
          showConfirmButton: false,
          showCancelButton: false,
          timerProgressBar: true
        });
        return;
      }

      // Esto se hace por fuera de CommentPane, habra que refrescarlo
      const comment = `Se ha cambiado el tincfile, el anterior era:\n ${old}`;
      // console.log(id, `ahora${tincfile} \n ANTES${old}`, comment);
      Comments.pushCommentToId("devices", id, comment)
        .then(() => {
          // this.comments = res;
          // this.$emit("commented", this.comment);
          this.refresh += 1;
        });

      this.$emit("tincFileChanged", id, tincfile); // Afuera se pondrá un comentario automatico
      ServiceApi.updateById(id, { tincfile });
    },
    onQuotationChanged(parent, newval, old) {
      console.log("Q Changed", parent, newval, old);
      ServiceApi.updateById(parent, { quotation:newval } )
        .then(res => {
          console.log("REL", res);
        });
    },
    commentError(err) {
      const { message: errstr = "Servidor no disponible", status = 500 } = err;
      this.messageAlert({
        icon: "error",
        title: "No se pudo guardar <b>comentario</b>",
        text: `${errstr}, CODE: ${status}`
      });
    },
    isValidRfc(str) {
      const rfc = /^(([ÑA-Z|ña-z|&amp;]{3}|[A-Z|a-z]{4})\d{2}((0[1-9]|1[012])(0[1-9]|1\d|2[0-8])|(0[13456789]|1[012])(29|30)|(0[13578]|1[02])31)(\w{2})([A|a|0-9]{1}))$|^(([ÑA-Z|ña-z|&amp;]{3}|[A-Z|a-z]{4})([02468][048]|[13579][26])0229)(\w{2})([A|a|0-9]{1})$/;
      console.log("testing RFC", rfc.test(str));
    },
    isValidIP(ip) {
      const regexIPV4 = new RegExp(/^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/);
      // console.log(regexIPV4.test(ip));
      return regexIPV4.test(ip);
    },
    formatDateTime(date) {
      const dateOrig = parseISO(date);
      if (!(dateOrig instanceof Date)) {
        return "-";
      }
      const datef = format(dateOrig, "dd MMM, yy HH:mm:ss", { locale: esmx });
      return datef;
    },
    tabClicked(tab) {
      this.queueSize = 0;
      this.queuePasteText = "";
      this.currentTab = tab;
    },
    onQueueSizeChanged(size) {
      this.queueSize = size;
    },
    toggleSharing(user) {
      // contiene el actual status de sharing y hay que invertirlo
      // console.log("Toggle sharing of:", user);
      const { _id = null } = this.currentItem;
      const { email = "Desconocido", sharing = false } = user ?? {};
      ServiceApi.toggleSharing(_id, user).then(() => {
        // console.log("Toggle sharing OK:", res);
        this.fillInitial();
        this.$emit("requestRefresh");

        const shared = !sharing; // ya realizo los cambios
        let msg = `Se ha compartido con ${email}`;
        if (!shared) {
          msg = `Se ha dejado de compartir con ${email}`;
        }
        this.messageAlert({
          icon: "success",
          title: msg
        });
      }).catch(err => {
        const { response = {}, message = "" } = err;
        const { data: dataResponse = {}, status: statusres = 400 } = response;
        const { error_string: errstr = message } = dataResponse;
        // console.log(statusres, errstr);
        this.messageAlert({
          icon: "error",
          title: `No se pudo compartir con ${email}`,
          text: `${errstr}, CODE: ${statusres}`
        });
      });
    },
    onDevclassChange(devclass) {
      const { _id = null } = this.currentItem;
      ServiceApi.updateById(_id, { devclass })
        .then(() => {
          this.fillInitial();
          this.$emit("requestRefresh", this.currentItem);
        })
        .catch(err => {
          const { response = {}, message = "" } = err;
          const { data: dataResponse = {}, status = 400 } = response;
          const { error_string: errstr = message } = dataResponse;
          // console.log(status, errstr);
          this.messageAlert({
            icon: "error",
            title: `No se pudo cambiar el tipo de dispositivo a <b>${devclass}</b>`,
            text: `${errstr}, CODE: ${status}`
          });
        });
    },
    openSocialnetsPlot() {
      window.open(`${this.API_ROOT}/api/devices/plots/${this.tincIp}/socialnets_${new Date().getFullYear()}.png`, this.tincIp);
    },
    messageAlert(info) {
      const {
        icon = "", title = "", text = "", timer = 3000
      } = info ?? {};
      this.$swal({
        position: "bottom-end",
        toast: true,
        icon,
        title,
        timer,
        html: text,
        confirmButtonText: "Ok",
        showCancelButton: false,
        showConfirmButton: false,
        timerProgressBar: true
      });
    }
  },
  mounted() {
    // Rellenar los posibles usuarios para compartir
    UsersApi.getUserList().then(res => {
      const { total = 0, rows = [] } = res.data;
      if (total < 1) {
        console.warn(
          "WARNING: No users on userlist or total mismatch, this is a security BUG! STOP NOW!"
        );
        return;
      }
      if (rows.length !== total) {
        console.warn(
          "WARNING: Total user count and rows total mismatch, this is a security BUG!"
        );
      }
      const { _id: myid } = this.user;
      const userlist = rows
        .filter(it => it._id !== myid) // ignorarse a si mismo
        .map(it => {
          const { _id, email, name } = it;
          return {
            id: _id,
            email,
            name
          };
        });
      this.availableUsersToShare = userlist;
    });
  }
};
</script>
